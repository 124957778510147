.gridPad {
    padding: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.txtMargin {
    margin-right: 10px;
    border-bottom: 1px solid black;
}

.txtMargin .inputRadius {
    text-align: center;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    width: 2.5em;
}

.fontOtp {
    font-size: 12px;
}

.Otp-Input {
    margin-left: 4rem;
    margin-right: 4rem;
}

.inputRadius:focus-visible {
    outline: none;
}