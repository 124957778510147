.cus-header-menu {
    transform: translate(-200px, 50px) !important;
}

.cus-header-menu-item {
    display: none !important;
}

@media only screen and (max-width: 768px) {
    .cus-header-menu.show {
        transform: translate3d(0px, 50px, 0px) !important;
    }
    .cus-header-menu-item {
        display: flex !important;
    }
}

@media only screen and (max-width: 425px) {
    .cus-header-menu.show {
        width: max-content !important;
        transform: translate3d(-100px, 50px, 0px) !important;
    }
}